// reviews

.reviews{
	display: flex;

	&-item{
		width: calc(50% - 30px);
		margin: 0 15px;

		&-sub{
			display: flex;
			
			&:not(:last-child){
				margin-bottom: 40px;
			}
		}
	}

	&-icon{
		width: 60px;
		height: 60px;
		border-radius: 50%;
		overflow: hidden;
		margin-right: 30px;
	}

	&-title{
		font-size: 21px;
		font-weight: 500;
		margin-bottom: 20px;
	}

	&-content{
		padding-top: 12px;
		width: calc(100% - 90px);

		p{
			margin-bottom: 25px;
		}
	}

	&-btn{
		display:inline-block;
		background: rgba($white, .2);
		border-radius: 10px;
		font-size: 16px;
		padding: 5px 20px;

		&:hover{
			background: $white;
			color: $dark;
		}
	}

	&-answer{
		padding-left: 30px;
		border-left: 1px solid rgba($white, .3);
		margin-top: 30px;

		&-icon{
			display: flex;
			img{
				width: 30px;
				height: 30px;
				border-radius: 50%;
				margin-right: 13px;
			}
		}
	}
}