// promo

.promo{
	display: flex;
	padding: 200px 120px 0;
	margin: 0 -15px;

	&-item{
		width: calc(33.3333% - 30px);
		margin: 0 15px;
		text-align: center;
		padding: 60px;
		background-image: linear-gradient(180deg, #4f9df7 0%, #257bde 100%);
		-webkit-transition: all 0.5s ease;
	  -moz-transition: all 0.5s ease;
	  -o-transition: all 0.5s ease;
	  transition: all 0.5s ease;

		&:hover{
			box-shadow: 10px 10px 22px rgba(0, 0, 0, 0.35);
			transform: translatey(-20px);
			-webkit-transition: all 0.2s ease;
		  -moz-transition: all 0.2s ease;
		  -o-transition: all 0.2s ease;
		  transition: all 0.2s ease;
		}
	}

	&-name{
		font-size: 36px;
		font-weight: 400;
		color: rgba($white, .7);
		margin-bottom: 25px;
	}

	&-price{
		font-size: 124px;
		font-weight: 500;
		color: $white;
		margin-bottom: 30px;
	}

	&-info{
		font-size: 14px;
		font-weight: 400;
		color: rgba($white, .7);
		margin-bottom: 60px;
	}

	&-btn{
		display: block;
		font-size: 21px;
		line-height: 60px;
		font-weight: 400;
		color: rgba($white, .7);
		background: rgba($white-blue, .2);
		border-radius: 15px;
		border: 1px solid #707070;
		width: 100%;

		&:hover{
			color: $dark;
			background: $white;
		}
	}
}