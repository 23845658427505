@media only screen and (max-width: 1680px){
	html{
		font-size: 15px;
	}

	.promo{
		padding: 150px 20px 0;
	}

	.table{
		&-head{
			font-size: 15px;
		}
	}
}

@media only screen and (max-width: 1520px){
	.menu{
    width: 20%;
    padding: 4.2rem 2.5rem 0 2.5rem;

    .logo{
    	margin-bottom: 2rem;
    }
	}

	.add-parking {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
	}

	.card{
		flex-wrap: wrap;

		&-item{
			margin-bottom: 30px;
	    width: calc(50% - 1.9rem);
		}

		&:not(:last-child){
			margin-bottom: 30px;
		}	
	}

	.chart-wrap{
		flex-wrap: wrap;

		> div:first-child{
			width: 100%;
			margin-bottom: 30px;
		}

		> div:last-child{
			width: 100%;
		}
	}


}

@media only screen and (max-width: 1400px){
	.promo{
		flex-wrap: wrap;
		margin-top: -20px;	
		padding: 30px 0;

		&-item{
			margin-top: 30px;
			padding: 30px;	
		}

		&-price{
			font-size: 80px;
		}

		&-name{
			font-size: 26px;
		}
	}

	.card.double{
		// flex-wrap: wrap;
		.chart-statistic{
			width: calc(100% - 1.9rem);
			margin-bottom: 30px;
		}
	}
}

@media only screen and (max-width: 1365px){
	footer{
		flex-wrap: wrap;
	}

	.footer-menu{
		width: 100%;
		margin-bottom: 20px;
	}

	.list-wrapper{
		flex-wrap: wrap;

		> div:first-child{
			width: calc(100% - 30px);
		}

		> div:last-child{
			width: calc(100% - 30px);
			margin-top: 30px;
		}
	}
}

@media only screen and (max-width: 1200px){
	html{
		font-size: 14px;
	}

	.filter{
		&-wrap{
			flex-wrap: wrap;
		}
		&-list{
			order: 1;
			width: 100%;
			margin-top: 20px;
			margin-bottom: 1rem;
		}
	}

	.menu{
		min-width: 80px;
		width: 10%;
    padding: 2rem 15px 0 15px;
    align-items: center;

    .logo{
    	img{
    		max-width: 100%;
    		width: 100%;
    	}
    }

		&-list-item{
			span{
				display: none;
			}
			a img{
				margin-right: 0;
			}
		}
	}

	.add-parking{
		margin: 0 -15px;
		width: calc(100% + 30px);

		span{
			display: none;
		}
		img{
			margin-right: 0;
		}
	}

	.user-info{
		&-icon{
			display: none;
		}
		&-name{
			font-size: 14px;
		}
		&-btn{
			font-size: 12px;
		}
	}

	.container__content{
		width: 90%;
		margin-left: 10%;
	}
}

@media only screen and (max-width: 991px){
	.container__content{
		padding: 2rem 1rem;
	}
	.promo{
		&-item{
			width: calc(100% - 30px);
		}
	}

	.reviews{
		flex-wrap: wrap;
		margin: 25px -15px 0;

		&-item{
			width: calc(100% - 30px);
		}

		&-icon {
	    width: 34px;
	    height: 34px;
	    border-radius: 50%;
	    overflow: hidden;
	    margin-right: 10px;

	    img{
	    	width: 100%;
	    }
		}

		&-content{
			width: calc(100% - 44px);
			padding-top: 6px;
			font-size: 14px;
		}

		&-title{
			font-size: 18px;
		}

		&-btn{
			font-size: 14px;
		}
	}
}

@media only screen and (max-width: 960px){
	.card-item,
	.card.double .card-item{
		width: calc(100% - 1.9rem);
		padding: 30px;
		text-align: center;
	}

	.card-title{
		font-size: 32px;
		&-sub{
			margin-bottom: 30px;
		}
	}

	#chartdiv, #chartdiv-2, #chartdiv-sub{
		height: 350px;
	}
}

@media only screen and (max-width: 900px){
	.table{
		&-wrapper{
			overflow-x: scroll;
		}
		&-head{
			min-width: 900px;
			font-size: 14px;
		}
		&-body{
			min-width: 900px;
			font-size: 12px;
		}
	}
}

@media only screen and (max-width: 768px){
	.menu{
		width: 80px;

		.logo{
			margin-bottom: 1rem;
		}
	}

	.container__content{
		width: calc(100% - 80px);
		margin-left: 80px;
	}

	.footer-menu{
		width: 100%;
		margin-bottom: 20px;
		flex-wrap: wrap;
		margin-top: -10px;

		&-item{
			margin-top: 10px;
		}
	}

	.filter-list{
		flex-wrap: wrap;
		&-item{
			margin-top: 15px;
		}
	}

	.chart-radio{
		flex-wrap: wrap;

		.legends{
			order: 1;
		}

		.kt-widget16__visual{
			width: 100%;
			margin-bottom: 20px;
		}
	}
}