// login

.login{
	background: $dark-blue;
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;
	justify-content: center;
	align-items: center;

	h1{
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 100px;
	}

	&-logo{
		margin-bottom: 140px;
		a{
			display: flex;
			flex-direction: column;
			span{
				margin-top: 8px;
				font-size: 12px;
				font-weight: 500;
			}
		}
	}

	&-form{
		width: 100%;
		max-width: 240px;
		text-align: center;
	}

	&-link{
		font-size: 16px;
		color: rgba($white, .7);
		text-decoration: underline;

		&:hover{
			text-decoration: none;
			color: $white;
		}
	}
}

.form{
	&-row{
		margin-bottom: 20px;
		
		&:last-of-type{
			margin-top: 49px;
			margin-bottom: 49px;
		}
	}

	&-input{
		border-radius: 15px;
		border: 1px solid rgba(112,112,112, .2);
		background: rgba($white, .2);
		font-size: 16px;
		font-weight: 400;
		color: rgba($white, .5);
		line-height: 58px;
		height: 60px;
		width: 100%;
		text-align: center;
		padding: 0 15px;

		&:focus{
			border-color: rgba(112,112,112, .8);
		}

		&::-webkit-input-placeholder,
		&::-moz-placeholder,
		&:-ms-input-placeholder,
		&:-moz-placeholder,
		&::placeholder{
			color: rgba($white, .5);
		}
	}

	&-btn{
		width: 100%;
		background-image: linear-gradient(106deg, #4f9df7 0%, #257bde 100%);
		border: 1px solid #707070;
		border-radius: 15px;
		font-size: 16px;
		line-height: 58px;
		color: $white;
		cursor: pointer;

		&:hover{
			background: $white;
			color: $dark;
		}
	}
}

.form-input::-webkit-input-placeholder { /* Edge */
  color: rgba($white, .5);
  font-size: 16px;
	font-weight: 400;
}

.form-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgba($white, .5);
  font-size: 16px;
		font-weight: 400;
}

.form-input::placeholder {
  color: rgba($white, .5);
  font-size: 16px;
	font-weight: 400;
}