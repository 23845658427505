.btn{

}


.add-parking{
  background: rgba(181, 218, 255, .2);
  line-height: 91px;
  height: 91px;
  color: rgba(255,255,255, .5);
  font-size: 18px;
  font-weight: 400; 
  margin-left: -5.05rem;
  margin-right: -5.05rem;
  display: flex;
  justify-content: center;
  align-items: center;

  img{
    vertical-align: middle;
    margin-right: 20px;
    opacity: .5;
    height: 16px;
  }

  &:hover{
    background: rgba(181, 218, 255, .5);
    color: rgba(255,255,255, 1);

    img{
      opacity: 1;
    }
  }
}


@media only screen and (max-width: 991px){
 
}