// statistic

.statistics-table{

	.table-head{
		div:nth-child(1){
			width: 3%;
		}
		div:nth-child(2){
			width: 9%;
		}
		div:nth-child(3){
			width: 15%;
		}
		div:nth-child(4){
			width: 15%;
		}
		div:nth-child(5){
			width: 20%;
		}
		div:nth-child(6){
			width: 20%;
		}
		div:nth-child(7){
			width: 17%;
		}
	}
	.table-body-item{
    div:nth-child(1){
			width: 3%;
		}
		div:nth-child(2){
			width: 9%;
		}
		div:nth-child(3){
			width: 15%;
		}
		div:nth-child(4){
			width: 15%;
		}
		div:nth-child(5){
			width: 20%;
		}
		div:nth-child(6){
			width: 20%;
		}
		div:nth-child(7){
			width: 17%;
		}
	}

}