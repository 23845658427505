@import url('https://fonts.googleapis.com/css?family=Rubik:400,500,700&display=swap&subset=cyrillic');

$font-size: 16px;
$line-height: 1.2;
$font-family: 'Rubik', sans-serif;

// colors
$dark: #2e2e2e;
$dark-blue: #033255;
$white: #fff;
$white-gray: #a3a3a3;
$white-blue: #b5daff;
$green: #00ff80;
$main-bg: linear-gradient(144deg, #287ee0 0%, #1a589f 100%);