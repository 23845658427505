// main style

.container{
	display: flex;
}

.select{
	align-self: flex-end;
}

.select2.select2-container.select2-container--default{
	width: auto !important;
	height: 60px;
	z-index: 999;
	margin-right: 0 !important;
}

.select2-container--open .select2-dropdown--below{
	border-radius: 10px;
	background-color: rgba(19,97,186, .8);
	border: none;
	overflow: hidden;
	margin-top: -10px;
	padding-top: 20px;
	z-index: 99;
}

.select2-results__option{
	padding: 20px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected],
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: rgba($white-blue, .2);
  color: $white;
}

.select2-container--default .select2-selection--single .select2-selection__rendered{
	min-width: 255px;
	height: 60px;
	line-height: 60px;
	padding: 0 2.3rem 0 1.5rem;
	border-radius: 10px;
	background-color: rgba(51, 132, 226, .8);
}

.select2-selection__arrow{
	width: 12px !important;
	height: 60px !important;
	z-index: 999;
	opacity: 1 !important;
	right: 23px !important;
	b{
		border: none !important;
		background: url('../images/select-arrow.png') 50% 50% no-repeat;
		background-size: cover;
		width: 12px !important;
		height: 7px !important;
	}
}

.menu{
	width: 20%;
	padding: 6.2rem 5.05rem 0 5.05rem;
	background: $dark-blue;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.logo{
		display: flex;
		flex-direction: column;
		margin-bottom: 6.2rem;
		span{
			display:inline-block;
			margin-top: 4px;
			color: $white;
			font-family: $font-family;
			font-size: 9px;
			font-weight: 500;
		}
	}

	&-list{
		&-item{
			&:not(:last-child) {
				margin-bottom: 2.4rem;
			}

			a{
				color: $white-gray;
				display: flex;
				align-items: center;

				&:hover{
					color: $white;
					img{
						opacity: 1;	
						-webkit-transition: all 0.2s ease;
					  -moz-transition: all 0.2s ease;
					  -o-transition: all 0.2s ease;
					  transition: all 0.2s ease;
					}
				}
			}

			img{
				display: inline-block;
				margin-right: 20px;
				opacity: .5;
				-webkit-transition: all 0.5s ease;
			  -moz-transition: all 0.5s ease;
			  -o-transition: all 0.5s ease;
			  transition: all 0.5s ease;
			}

			&.active a{
				color: $white;
				img{
					opacity: 1;
				}
			}
		}
	}
}

.user-info{
	display: flex;

	&-icon{
		margin-right: 20px;
	}

	&-name{
		color: #fff;
		font-size: 18px;
		font-weight: 500;
		margin-bottom: 10px;
	}
	&-btn{
		font-size: 16px;
		font-weight: 400;
		opacity: .6;
		color: #fff;
		&:hover{
			opacity: 1;
		}
	}
}

.container__content{
	display: block;
	width: 80%;
	min-height: 100vh;
	margin-left: 20%;
	padding: 4rem 2rem;
	background: $main-bg;
}

.wrap-content{
	box-shadow: 10px 10px 6px rgba(0, 0, 0, 0.15);
	border-radius: 20px;
	background-image: linear-gradient(123deg, rgba(79, 157, 247, .5) 0%, rgba(37,123,222, .5) 100%);
}

.filter{
	&-wrap{
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		+ .card{
			margin-top: 2rem;
		}
	}

	&-list{
		display: flex;
		margin: 0 -.95rem 2rem;
		&-item{
			margin-left: .95rem;
			margin-right: .95rem;
			a{
				display: inline-block;
				padding: 5px 20px 6px;
				font-size: 16px;
				font-weight: 400;
				border-radius: 10px;
				color: $white;
				background: rgba($white, .2);
				
				&.active,
				&:hover{
					background: $white;
					color: $dark;
				}
			}
		}
	}
}

.card{
	display: flex;
	margin-left: -.95rem;
	margin-right: -.95rem;

	&:not(:last-child){
		margin-bottom: 60px;
	}
	
	&.double{
		.card-item{
			width: calc(50% - 1.9rem);
		}
	}
	&-item{
		padding: 60px;
		width: calc(33.3333% - 1.9rem);
		margin-left: .95rem;
		margin-right: .95rem;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		flex-direction: column;
	}

	&-icon{
		margin-bottom: 2rem;
	}
	&-title{
		font-size: 42px;
		font-weight: 500;
		color: $white;
		margin-bottom: .95rem;
		span{
			color: #b5daff;
		}
		&-sub{
			display: block;
			font-size: 18px;
			margin-bottom: 60px;
		}
	}
}

.chart-wrap{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	margin: 0 -.95rem;

	&:not(:last-child){
		margin-bottom: 60px;
	}
	> div {
		margin-left: .95rem;
		margin-right: .95rem;
		&:first-child{
			width: calc(70% - 2rem);
		}
		&:last-child{
			width: calc(30% - 2rem);
		}
	}
}

// [aria-describedby="id-183-description"] {
// 	display: none;
// }

.statistics{
	padding: 2rem 2.1rem 2rem 2rem;

	.scroll{
		max-height: 438px;
		padding-left: 2.2rem;
		position: relative;

		.ps__rail-y{
			right: inherit !important;
			left: 0;
			width: 4px;
			border-radius: 6px;
			background: rgba(255,255,255, .5);

			.ps__thumb-y{
				background: #fff;
				width: 4px;
				right: 0;
				opacity: 1;
			}
		}
	}
	
	&-item{
		display: flex;
		&:not(:last-child){
			margin-bottom: 2.3rem;
		}
	}

	&-time{
		font-size: 24px;
		font-weight: 500;
		color: $white;
		margin-right: 39px;
	}

	&-content{
		span{
			display:inline-block;
			padding: 5px 1rem;
			border-radius: 10px;
			font-size: 16px;
			color: $white;

			&:not(:last-child){
				margin-bottom: .95rem;
				margin-right: 20px;
			}
		}
	}

	&-rent{
		background: rgba($white, .2);
		margin-right: 0 !important;

		&.long{
			background: rgba($green, .2);
		}
	}

	&-number{
		color: $dark !important;
		background: #b5daff;
		font-weight: 500;
	}

	&-place{
		padding: 5px 0 !important;
	}
}

#chartdiv,
#chartdiv-2,
#chartdiv-sub{
  height: 500px;
  width: 100%;  
}

#chartdiv-sub g{
  max-width: 90% !important;
}

.chart-radio{
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 3.8rem;
}

.legends{
	&-item{
		display: flex;
		color: $white;
		font-size: 20px;
		font-weight: 400;
		margin-bottom: 2rem;
	}
	&-color{
		display: block;
		width: 30px;
		height: 30px;
		border-radius: 5px;
		margin-right: 30px;

		&.first{
			background-color: #b568ef;
		}
		&.second{
			background-color: #4c37bc;
		}
		&.tree{
			background-color: #21ddff;
		}
	}
	&-status{
		width: calc(100% - 60px);
	}
}

.select-date{
	input{
		max-width: 400px;
	}
}

// statistic

.chart-statistic{
	.chart-radio{
		padding: 30px;

		.legends-color{
			display: block;
			width: 2rem;
			height: 2rem;
			border-radius: 5px;
			margin-right: 3.8rem;

			&.first{
				background: #ddb15d;
			}
			&.second{
				background: #a1f59d;
			}
			&.tree{
				background: #4c37bc;
			}
			&.four{
				background: #004eff;
			}
			&.five{
				background: #eb00ff;
			}
		}
	}
}

.statistics-table{
	max-width: 100% !important;
}

// DATEPICKER

.daterangepicker{
	background: #1e3c52;
	border-color: #1e3c52;

	&::after{
		border-bottom-color: #1e3c52;
	}
	&::before{
		border-color: transparent;
	}

	.cancelBtn,
	.applyBtn,
	.btn{
		color: #fff;
	}

	.calendar-table{
		background: #1e3c52;
		border-color: #1e3c52;	
		
		th,
		td{
			color: #fff;
			border: none;

			&.in-range{
				background: #1a589f;
			}

			&.end-date{
				background: #357ebd;
			}

			&.off{
				background: none;
				opacity: .6;
			}

			&.next,
			&.prev{
				&:hover{
					background: #357ebd;
				}
			}
		}

		td{
			border: 1px solid #357ebd;
			&:hover{
				background: #357ebd;
			}
		}

		.next,
		.prev{
			span{
				border-color: #fff;
			}
		}
	}
}

// admin new version style

.list-wrapper.full-width{
	margin-bottom: 30px;
	> div:first-child{
		width: 100%;
	}	

	.table{
		max-width: 100%;
	}


	.table-head{
		font-size: 16px;
		line-height: 1.2;
		padding-top: 15px;
		padding-bottom: 15px;
		align-items: center;

		div:nth-child(1){
			width: 12%;
		}
		div:nth-child(2){
			width: 10%;
		}
		div:nth-child(3){
			width: 15%;
		}
		div:nth-child(4){
			width: 15%;
		}
		div:nth-child(5){
			width: 13%;
		}
		div:nth-child(6){
			width: 15%;
		}
		div:nth-child(7){
			width: 17%;
		}
	}
	.table-body-item{
		align-items: center;
    div:nth-child(1){
			width: 12%;
		}
		div:nth-child(2){
			width: 10%;
		}
		div:nth-child(3){
			width: 15%;
		}
		div:nth-child(4){
			width: 15%;
		}
		div:nth-child(5){
			width: 13%;
		}
		div:nth-child(6){
			width: 14%;
		}
		div:nth-child(7){
			width: 17%;
		}
	}
}

#add-user.box-modal{
	position: relative;
	
	label{
		font-size: 20px;
		padding-left: 13px;
	}
	input{
		width: 100%;
    border-radius: 15px;
    border: 1px solid rgba(112, 112, 112, 0.2);
    background: rgba(255, 255, 255, 0.2);
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.8);
    line-height: 58px;
    height: 60px;
    width: 100%;
    padding: 0 15px;
	}

	.form-control{
		margin: 0 !important;
	}

	.arcticmodal-close{
		position: absolute;
	  right: 32px;
	  top: 32px;
	  width: 32px;
	  height: 32px;
	  opacity: 0.6;
	  cursor: pointer;

	  &:hover{
	  	opacity: 1;
	  	&::before{
	  		transform: rotate(50deg);
	  		-webkit-transition: all 0.2s ease;
			  -moz-transition: all 0.2s ease;
			  -o-transition: all 0.2s ease;
			  transition: all 0.2s ease;
	  	}
	  	&::after{
	  		transform: rotate(-50deg);
	  		-webkit-transition: all 0.2s ease;
			  -moz-transition: all 0.2s ease;
			  -o-transition: all 0.2s ease;
			  transition: all 0.2s ease;
	  	}
	  }
	  &::before,
	  &::after{
			position: absolute;
			top: 0;
		  left: 15px;
		  height: 33px;
		  width: 2px;
		  background-color: #fff;
		  content: ' ';
		  -webkit-transition: all 0.5s ease;
		  -moz-transition: all 0.5s ease;
		  -o-transition: all 0.5s ease;
		  transition: all 0.5s ease;
	  }
	  &::before{
			transform: rotate(45deg);
	  }
	  &::after{
	  	transform: rotate(-45deg);
	  }
	}
}

.add-user-wrap{
	display: flex;
	justify-content: flex-end; 

	.btn{
		width: auto !important;
		// background: rgba(181, 218, 255, 0.2);
  //   line-height: 51px;
  //   height: 51px;
  //   color: rgba(255, 255, 255, 0.5);
  //   font-size: 18px;
  //   font-weight: 400;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   border-radius: 3px;
	  -webkit-transition: all 0.5s ease;
	  -moz-transition: all 0.5s ease;
	  -o-transition: all 0.5s ease;
	  transition: all 0.5s ease;
    &:hover{
	    -webkit-transition: all 0.2s ease;
	  	-moz-transition: all 0.2s ease;
		  -o-transition: all 0.2s ease;
		  transition: all 0.2s ease;
    }
	}
}

.helper-menu{
	width: 40px;
	height: 40px;
	position: relative;
	border-radius: 6px;
	cursor: pointer;
	-webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;

	&:hover,
	&.open{
		-webkit-transition: all 0.2s ease;
	  -moz-transition: all 0.2s ease;
	  -o-transition: all 0.2s ease;
	  transition: all 0.2s ease;
	}
	
	span{
		width: 6px;
		height: 6px;
		background: #fff;
		border-radius: 50%;
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		&::before,
		&::after{
			content: "";
			width: 6px;
			height: 6px;
			background: #fff;
			border-radius: 50%;
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		&::before{
			margin-left: -10px;
		}

		&::after{
			margin-left: 10px;
		}
	}

	&:hover{
		.helper-menu-list{
			opacity: 1;
			visibility: visible;
			-webkit-transition: all 0.2s ease;
		  -moz-transition: all 0.2s ease;
		  -o-transition: all 0.2s ease;
		  transition: all 0.2s ease;
		}
	}

	&-list{
		z-index: 10;
		opacity: 0;
		visibility: hidden;
		position: absolute;
		top: 100%;
		left: 0;
		width: 200px;
		background: #033255;
		padding: 15px 10px;
		border-radius: 6px;
		-webkit-transition: all 0.5s ease;
	  -moz-transition: all 0.5s ease;
	  -o-transition: all 0.5s ease;
	  transition: all 0.5s ease;
		li{
			&:not(:last-child){
				margin-bottom: 8px;
			}
		}
	}
}

.add-user{
	.modal-add-parking-fio-item{
		width: 100%;
		margin-bottom: 25px;

		label{
			margin-bottom: 10px;
		}
	}

	.modal-add-parking-sub-item{
		width: 100%;
		margin-bottom: 25px;
		.select2.select2-container.select2-container--default{
			width: 100% !important;
		}
	}
}
.select2-results{
	ul li:first-child{
		display: none !important;
	}
}

.form-control{
	display: flex;
	margin-bottom: 30px;
	margin-left: -10px;
	margin-right: -10px;
	&-item{
		width: calc(33.3333% - 20px);
		margin-left: 10px;
		margin-right: 10px;

		&:not(:last-child){
			margin-bottom: 20px;
		}
		label{
			display: block;
			margin-bottom: 10px;
			font-size: 18px;
			padding-left: 13px;
		}
		input{
			width: 100%;
      border-radius: 15px;
	    border: 1px solid rgba(112, 112, 112, 0.2);
	    background: rgba(255, 255, 255, 0.2);
	    font-size: 16px;
	    font-weight: 400;
	    color: rgba(255, 255, 255, 0.8);
	    line-height: 58px;
	    height: 60px;
	    width: 100%;
	    padding: 0 15px;
		}
	}
}

.menu{
	&.menu-admin{
    padding: 4.2rem 5.05rem 0 5.05rem;
    justify-content: flex-start;

    .logo{
    	margin-bottom: 3.2rem;
    }

    .menu-list{
    	margin-bottom: 6.6rem;
    }
	}
}