// client

.table{
	max-width: 1020px;
	width: 100%;
	&-wrapper{
		width: 100%;
		box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
		background-image: linear-gradient(93deg, rgba(43, 115, 198, .5) 0%, rgba(37, 113, 199, .5) 100%);
		border-radius: 20px;
	}

	&-head{
		display: flex;
		justify-content: space-between;
		background-image: linear-gradient(93deg, #2b73c6 0%, #2571c7 100%);
		border-radius: 20px;
		line-height: 60px;	
		background: #2571c7;
		font-size: 18px;
		font-weight: 500;

		div{
			padding: 0 20px;
			&:nth-child(1){
				width: 15%;
			}
			&:nth-child(2){
				width: 20%;
			}
			&:nth-child(3){
				width: 25%;
			}
			&:nth-child(4){
				width: 20%;
			}
			&:nth-child(5){
				width: 20%;
			}
		}
	}

	&-body{
		&-item{
			display: flex;
			justify-content: space-between;
			font-size: 14px;
			font-weight: 400;

			div{
				padding: 20px 20px;
				&:nth-child(1){
					width: 15%;
				}
				&:nth-child(2){
					width: 20%;
				}
				&:nth-child(3){
					width: 25%;
				}
				&:nth-child(4){
					width: 20%;
				}
				&:nth-child(5){
					width: 20%;
				}

				.status{
					display: inline-block;
					border-radius: 10px;
					padding: 5px 10px;

					&.long{
						background: rgba($green, .2);
					}
					&.short{
						background: rgba($white, .2);
					}
				}
			}
		}
	}
}