
/*@import 'reset';*/

/* DEFAULT STYLES */
@import "variables";
@import "reset";
@import "default";

@import "buttons";


/* Local parts */ 
@import "admin-page";
@import "promo";
@import "client";
@import "reviews";
@import "list";
@import "login";
@import "statistic";

// responsive
@import "media";
