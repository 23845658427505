// list

.list-wrapper{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin: 30px -15px 0;

	> div{
		width: calc(30% - 30px);
		margin: 0 15px;
		&:first-child{
			width: calc(80% - 30px);
		}
	}
	.wrap-content{
		width: 100% !important;
		margin: 0;
	}

	.chart-radio{
		flex-direction: column;
	}

	.kt-widget16__visual{
		margin-bottom: 60px;
	}

	.table-head{
		div:nth-child(1){
			width: 5%;
		}
		div:nth-child(2){
			width: 15%;
		}
		div:nth-child(3){
			width: 13%;
		}
		div:nth-child(4){
			width: 15%;
		}
		div:nth-child(5){
			width: 20%;
		}
		div:nth-child(6){
			width: 19%;
		}
		div:nth-child(7){
			width: 13%;
		}
	}
	.table-body-item{
    div:nth-child(1){
			width: 5%;
		}
		div:nth-child(2){
			width: 15%;
		}
		div:nth-child(3){
			width: 13%;
		}
		div:nth-child(4){
			width: 15%;
		}
		div:nth-child(5){
			width: 20%;
		}
		div:nth-child(6){
			width: 19%;
		}
		div:nth-child(7){
			width: 13%;
		}
	}

}
